import React from 'react'
import CloudCost from '../../components/faq/CloudCost'
import PageStructure from '../../components/shared/PageStructure'

const CloudCostPage = () => {
  return (
    <PageStructure text={'WHAT DOES THE CLOUD COST?'} page={CloudCost} />
  )
}

export default CloudCostPage