import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import React from 'react'
import ContactUsRef from '../ContactUsRef';
import LinkCtr from '../shared/LinkCtr';

const CloudCost = () => {
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.only("xs"));

  return (
    <React.Fragment>
        <Box sx={{width:'100%', display:'flex', flexDirection:'column', alignItems:'center'}} >
            <Box sx={{margin:'5% 5% 3% 5%', color:'#9c9c9c', fontSize:'15px !important', fontWeight:'400'}}>             
                <Typography component={'p'} sx={{textAlign:'center'}}>
                    <img decoding="async"  style={{float:isMatch ? 'none' : 'right', margin:'9px 0 20px 28px', marginLeft:isMatch ? '0px': '28px'}} src="/assets/images/cloudcost/what-does-the-cloud-cost-300x200.jpg" alt="" width="300" height="200" srcSet="/assets/images/cloudcost/what-does-the-cloud-cost-300x200.jpg 300w, /assets/images/cloudcost/what-does-the-cloud-cost-768x512.jpg 768w, /assets/images/cloudcost/what-does-the-cloud-cost-1024x683.jpg 1024w, /assets/images/cloudcost/what-does-the-cloud-cost-1600x1067.jpg 1600w" sizes="(max-width: 300px) 100vw, 300px" />
                </Typography>
                <Typography sx={{marginTop:'16px'}} component={'p'}>It’s one of the most asked cloud computing questions: Is it less expensive to run your business in the cloud than in an on-prem IT environment?</Typography>
                <Typography sx={{marginTop:'16px'}} varient="p">The answer is a definitive maybe.</Typography>
                <Typography sx={{marginTop:'16px'}} component={'p'}>Despite what you may read elsewhere, moving to the cloud is not guaranteed to save you a ton of money. (Honesty from a company? Gasp!) But, the cloud ‘pay as and go’ model is kind to your cash flow and easier to plan for and you only pay for the options you want.</Typography>
                <Typography sx={{marginTop:'16px'}} component={'p'}>Moving to the cloud helps you manage your business cost more easily. No need to keep spending cash upgrading your servers and no need for large capital to get started in the cloud. Instead you pay the same fee every month. Simple, predictable and easy to plan for. What’s even better is that you can scale up if you need more and scale down if you need less – giving you more control.</Typography>
                <Typography sx={{marginTop:'16px'}} component={'p'}>There are a lot of factors that go into determining cost for cloud services,; like number of users, types of applications to be run in the cloud, amount of storage space, level of security, etc. – the but the good news is, it’s pretty easy to calculate.</Typography>
                <Typography sx={{marginTop:'16px'}} component={'p'}>Give us 2 minutes of your time answering <LinkCtr toUrl="/get-a-quote" withoutHoverColor='#000000' text='a few questions' /> and we’ll get right back to you with a quote designed specifically for your business. We look forward to chatting with you to show how Cloud Service Geeks can help move your business to the cloud. Talk to ya soon!</Typography>
                
            </Box>
        </Box>
        <ContactUsRef />
    </React.Fragment>
  )
}

export default CloudCost