import React from 'react'
import { Box, Typography } from '@mui/material'
const ThankyouComp = () => {
    return (
        <Box sx={{width:'80%', margin:'7% auto', textAlign:'center'}}>
            <Typography>
                Submission Successful
            </Typography>
            <Typography>
                Someone will contact you shortly!
            </Typography>
        </Box>
      )
}

export default ThankyouComp