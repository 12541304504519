import { Box, Typography, useMediaQuery, useTheme, } from '@mui/material'
import React from 'react';
import ContactUsRef from '../ContactUsRef';
import LinkCtr from '../shared/LinkCtr';

const IsTheCloudSecure = () => {
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.only("xs"));
  return (
    <React.Fragment>
    <Box sx={{width:'100%', display:'flex', flexDirection:'column', alignItems:'center'}} >
        <Box sx={{margin:'5% 10% 3% 10%', color:'#9c9c9c', fontSize:'15px !important', fontWeight:'400'}}>             
            <Typography component={'p'} sx={{textAlign:'center'}}>
                <img decoding="async" style={{float:isMatch ? 'none' : 'right', margin:'9px 0 20px 28px', marginLeft:isMatch ? '0px': '28px'}}  src="/assets/images/iscloudsecure/is-the-cloud-secure-300x300.jpg" alt="" width="300" height="300" srcSet="/assets/images/iscloudsecure/is-the-cloud-secure-300x300.jpg 300w, /assets/images/iscloudsecure/is-the-cloud-secure-150x150.jpg 150w, /assets/images/iscloudsecure/is-the-cloud-secure-768x768.jpg 768w, /assets/images/iscloudsecure/is-the-cloud-secure-1024x1024.jpg 1024w, /assets/images/iscloudsecure/is-the-cloud-secure-100x100.jpg 100w, /assets/images/iscloudsecure/is-the-cloud-secure-1600x1600.jpg 1600w" sizes="(max-width: 300px) 100vw, 300px" />
            </Typography>
            <Typography sx={{marginTop:'16px'}} component={'p'}>It’s normal to fear what we don’t know – especially when it comes to new technologies. When you talk about security, the real question is how will moving your business to the cloud provide even more security than what you have now. Let us walk you through the basics –</Typography>
            <Typography sx={{marginTop:'16px', fontWeight:'800'}} varient="h5">IT’S INDESTRUCTIBLE</Typography>
            <Typography sx={{marginTop:'16px'}} component={'p'}>In most offices, a locked door is the main defense to protect networks and servers, important files, and business-related data. It wouldn’t take much to compromise it.</Typography>
            <Typography sx={{marginTop:'16px'}} component={'p'}>On the other hand, if your work apps are in the cloud, they aren’t physically vulnerable to localized destruction. If a disaster happens in your office – fire, flood, theft, or good old-fashioned server corruption–you can get back to work as quickly as it takes to find another computer.</Typography>
            <Typography sx={{marginTop:'16px', fontWeight:'800'}} varient="h5">IT’S MEGA SECURE</Typography>
            <Typography sx={{marginTop:'16px'}} component={'p'}>Your data will be stored in a state of the art, mega-secure, <a className='link' href="https://www.microsoft.com/en-us/cloud-platform/global-datacenters" target="_blank" rel="noopener">Microsoft Azure Data Center</a>. Take a <a className='link' href="http://cloud-platform-assets.azurewebsites.net/datacenter/index.html" target="_blank" rel="noopener">virtual tour</a> to see why this is a major step up from what most businesses can afford.</Typography>
            <Typography sx={{marginTop:'24px'}} component={'p'}>Here are a few facts about Azure:</Typography>
            <Typography sx={{marginTop:'16px'}} component={'p'}>
                <ul>
                    <li>They have the most comprehensive compliance coverage of any cloud provider.</li>
                    <li>They have more certifications than any other cloud provider.</li>
                    <li>They are the Industry leader for customer advocacy and privacy protection.</li>
                    <li>They provide unique data residency guarantees.</li>
                    <li>They have invested over $15 billion in building their cloud infrastructure.</li>
                    <li>They have over 1 million servers hosted in their datacenter.</li>
                    <li>They have 100-plus datacenters in its global cloud infrastructure portfolio.</li>
                </ul>
            </Typography>
            <Typography sx={{marginTop:'24px'}} component={'p'}>Truth is, right now your office is probably nowhere near as secure.</Typography>
            <Typography sx={{marginTop:'16px'}} component={'p'}>So it’s secure, but is it reliable?  Learn more about reliability in our FAQ: <LinkCtr toUrl="/faq/is-the-cloud-reliable/" text='Is the cloud reliable?' withoutHoverColor='#000000' /></Typography>
        
        </Box>
    </Box>
    <ContactUsRef />
</React.Fragment>
  )
}

export default IsTheCloudSecure