import { Box, Typography, useMediaQuery, useTheme, } from '@mui/material'
import React from 'react';
import ContactUsRef from '../ContactUsRef';
import LinkCtr from '../shared/LinkCtr';

const IsTheCloudReliable = () => {
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.only("xs"));
  return (
    <React.Fragment>
    <Box sx={{width:'100%', display:'flex', flexDirection:'column', alignItems:'center'}} >
        <Box sx={{margin:'5% 5% 3% 5%', color:'#9c9c9c', fontSize:'15px !important', fontWeight:'400'}}>             
            <Typography component={'p'}  sx={{textAlign:'center'}}>
                <img decoding="async" style={{float:isMatch ? 'none' : 'right', margin:'9px 0 20px 28px', marginLeft:isMatch ? '0px': '28px'}}  src="/assets/images/isthecloudreliable/is-the-cloud-reliable-300x300.jpg" alt="" width="300" height="300" srcSet="/assets/images/isthecloudreliable/is-the-cloud-reliable-300x300.jpg 300w, /assets/images/isthecloudreliable/is-the-cloud-reliable-150x150.jpg 150w, /assets/images/isthecloudreliable/is-the-cloud-reliable-768x768.jpg 768w, /assets/images/isthecloudreliable/is-the-cloud-reliable-1024x1024.jpg 1024w, /assets/images/isthecloudreliable/is-the-cloud-reliable-100x100.jpg 100w, /assets/images/isthecloudreliable/is-the-cloud-reliable-1600x1600.jpg 1600w" sizes="(max-width: 300px) 100vw, 300px" />
            </Typography>
            <Typography sx={{marginTop:'16px'}} component={'p'}>Microsoft Azure reports 99.99+% uptime on their cloud service over the last 12 months. How much downtime do you typically suffer through each year? We know reliability runs deeper for you than just uptime. Take a look at these other benefits of running your business in the cloud:</Typography>
            <Typography sx={{marginTop:'16px', fontWeight:'800'}} varient="h5">WE BACKUP OUR BACKUPS</Typography>
            <Typography sx={{marginTop:'16px'}} component={'p'}>Do you lose sleep wondering if your systems are backed up? Do you run scenarios over and over in your head on what a data loss would mean to your organization?</Typography>
            <Typography sx={{marginTop:'16px'}} component={'p'}>You can rest assured with Cloud Service Geeks as we provide a redundant backup system with one backup stored in Microsoft Vault and another in an unrelated 3rd-party data center (that’s right – we backup our backup!).</Typography>
            <Typography sx={{marginTop:'16px'}} component={'p'}>Not only is your data safely stored off site from your office (away from any physical threats), your information is stored in different data centers, just in case one is ever compromised.</Typography>
            <Typography sx={{marginTop:'16px'}} component={'p'}>Still worried about backups? You are in good company because so is our CEO! We have a team that audits your backup each day to make sure they are successfully completed. So you can rest easy.</Typography>
            <Typography sx={{marginTop:'16px', fontWeight:'800'}} varient="h5">PROTECTS AGAINST THREATS</Typography>
            <Typography sx={{marginTop:'16px'}} component={'p'}>Security is a priority for every business. Technology is getting more complex, and security threats are getting more savage. It’s nearly impossible for small businesses to stay ahead of viruses and firewall threats.</Typography>
            <Typography sx={{marginTop:'24px'}} component={'p'}>One of the biggest ways to ensure your data’s safety is to have up to date malware protection that is managed automatically and updated regularly. We use world-class malware software for your cloud services and virtual desktops to identify and remove viruses, spyware, and other malicious software. No worries – we manage and update your protection regularly without you having to lift a finger.</Typography>
            <Typography sx={{marginTop:'16px'}} component={'div'}>
               <span style={{fontWeight:'600'}} component={'span'}>Real-time protection – </span>
               &nbsp;monitors activity in Cloud Services and on Virtual Machines to detect and block malware execution.
            </Typography>
            <Typography sx={{marginTop:'16px'}} component={'div'}>
               <span style={{fontWeight:'600'}} component={'span'}>Scheduled scanning – </span>
               &nbsp;periodically performs targeted scanning to detect malware, including actively running programs.
            </Typography>
            <Typography sx={{marginTop:'16px'}} component={'div'}>
               <span style={{fontWeight:'600'}} component={'span'}>Malware remediation – </span>
               &nbsp;automatically takes action on detected malware, such as deleting or quarantining malicious files and cleaning up malicious registry entries.
            </Typography>
            <Typography sx={{marginTop:'16px'}} component={'div'}>
               <span style={{fontWeight:'600'}} component={'span'}>Signature updates – </span>
               &nbsp;automatically installs the latest protection signatures (virus definitions) to ensure protection is up-to-date on a pre-determined frequency.
            </Typography>
            <Typography sx={{marginTop:'16px'}} component={'div'}>
               <span style={{fontWeight:'600'}} component={'span'}>Active protection – </span>
               &nbsp;reports detected threats and suspicious resources to Microsoft Azure to ensure rapid response to the evolving threat landscape.
            </Typography>
            <Typography sx={{marginTop:'16px'}} component={'div'}>
               <span style={{fontWeight:'600'}} component={'span'}>Samples reporting – </span>
               &nbsp;provides and reports samples to the Microsoft Antimalware service to help refine the service and enable troubleshooting.
            </Typography>
            <Typography sx={{marginTop:'16px'}} component={'div'}>
               <span style={{fontWeight:'600'}} component={'span'}>Anti malware event collection –  </span>
               &nbsp;records the anti malware service health, suspicious activities, and actions taken in the operating system event log.
            </Typography>          
            <Typography sx={{marginTop:'16px'}} component={'p'}>It’s safe, it’s <LinkCtr toUrl="/faq/is-the-cloud-secure/" withoutHoverColor='#000000' text='secue' />, it’s reliable, but can I afford it? Learn more about what it costs in our FAQ: <LinkCtr toUrl="/faq/what-does-the-cloud-cost/" text='What does the cloud cost?' withoutHoverColor='#000000' /></Typography>
        
        </Box>
    </Box>
    <ContactUsRef />
    </React.Fragment>
  )
}

export default IsTheCloudReliable