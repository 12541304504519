import { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';

import Footer from "./components/Footer";
import Header from "./components/Header";
import MainPageComp from "./components/MainPageComp";
import WhatIsTheCouldPage from './pages/faq/WhatIsTheCouldPage';
import WhatWeDoPage from './pages/WhatWeDoPage';
import GetAQuotePage from './pages/GetAQuotePage';
import MoveMeToTheCloudPage from './pages/MoveMeToTheCloudPage';
import IsTheCloudSecurePage from './pages/faq/IsTheCloudSecurePage';
import IsTheCloudReliablePage from './pages/faq/IsTheCloudReliablePage';
import CloudCostPage from './pages/faq/CloudCostPage';
import ThankyouPage from './pages/ThankyouPage';
import ErrorPage from './pages/ErrorPage';
function App() {
  const [compIndex, setCompIndex] = useState(0);
  const menuClick = (index) =>{
    if(window.location.pathname !== "/"){
      window.location.pathname= "/";
    }      
    setCompIndex(index);
  }
  useEffect(() =>{
    // console.log('mahe: ', window.location);
  })
  return (
    <Router>
      <Header menuClick={menuClick}/>
      <Routes>
          <Route exact path='/' element={<MainPageComp index={compIndex}/>} />
          <Route path='/:id' element={<MainPageComp index={compIndex}/>} />
          <Route path='/what-we-do' element={<WhatWeDoPage />} />
          <Route path='/get-a-quote' element={<GetAQuotePage />} />
          <Route path='/move-me-to-the-cloud' element={<MoveMeToTheCloudPage />} />
          <Route path='/faq/what-is-the-cloud' element={<WhatIsTheCouldPage />} />
          <Route path='/faq/is-the-cloud-secure' element={<IsTheCloudSecurePage />} />
          <Route path='/faq/is-the-cloud-reliable' element={<IsTheCloudReliablePage />} />
          <Route path='/faq/what-does-the-cloud-cost' element={<CloudCostPage />} />
          <Route path='/thankyou' element={<ThankyouPage />} />
          <Route path='/error' element={<ErrorPage />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
