import { Box, Typography } from '@mui/material'
import React from 'react'

const CopyRight = () => {
  return (
    <Box  sx={{backgroundColor:"#101010", padding:"18px 0", color:"#666"}}>
        <Typography sx={{margin:"0 auto", textAlign:"center", fontSize:"11px"}}>
            © 2019 Cloud Service Geeks - an SE Company |  All rights reserved
        </Typography>
    </Box>
  )
}

export default CopyRight