import React from 'react'
import { Box, Typography } from '@mui/material';

const ErrorComp = () => {
    return (
        <Box sx={{width:'80%', margin:'7% auto', textAlign:'center'}}>
            <Typography color="#DD442C;">
                Something went wrong.. please try again!
            </Typography>
        </Box>
      )
}

export default ErrorComp