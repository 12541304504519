import React from 'react'
import IsTheCloudSecure from '../../components/faq/IsTheCloudSecure'
import PageStructure from '../../components/shared/PageStructure'

const IsTheCloudSecurePage = () => {
  return (
    <PageStructure text={'WHAT IS THE CLOUD?'} page={IsTheCloudSecure} />
  )
}

export default IsTheCloudSecurePage