import { Box, Typography, Stack, TextField, Button} from '@mui/material'
import React, { useState, useRef} from 'react';
import HCaptcha from "@hcaptcha/react-hcaptcha";
//import Axios from 'axios';
//import { baseApiUrl } from '../api/api-service';

const regEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
const source = "2";
const serverErrMsg = "Something went wrong.. please try again after some time!";
const validationErrMsg = "Please enter all the required fields.";

const GetAQuote = () => {
    const [token, setToken] = useState(null);
    const [name, setName] = useState("");
    const [nameError, setNameError] = useState(false);
    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState(false);
    const [business, setBusiness] = useState("");
    const [network, setNetwork] = useState("");
    const [noOfEmail, setNoOfEmail] = useState("");
    const [noOfOffice, setNoOfOffice] = useState("");
    const [noOfWS, setNoOfWS] = useState("");
    const [noOfMobile, setNoOfMobile] = useState("");
    const [message, setMessage] = useState("");
    const [success, setSuccess] = useState(false);
    const [isError, setIsError] = useState(false);
    const [errMsg, setErrorMsg] = useState(validationErrMsg);
    const [disableButton, setDisableButton] = useState(false);

    const captchaRef = useRef(null);
    const handleSubmit = () => {
      
      if(validateInputs()){
        setDisableButton(true);
        document.getElementById("quoteForm").submit();
        // Axios.post(baseApiUrl + "contact", {
        //     token, name, email, source, message, business, network, noOfEmail, noOfOffice, noOfWS, noOfMobile
        // })
        // .then(response => {
        //     setSuccess(true);
        // })
        // .catch(({response}) => {
        //     setErrorMsg(serverErrMsg);
        //     setIsError(true);
        //     setDisableButton(false);
        // })
        // .finally(() => {
        //     captchaRef.current.resetCaptcha();
        //     setToken("");
        //     setName("");
        //     setEmail("");
        //     setBusiness("");
        //     setNetwork("");
        //     setNoOfEmail("");
        //     setNoOfOffice("");
        //     setNoOfWS("");
        //     setNoOfMobile("");
        //     setMessage("");
        //     setDisableButton(false);
        // });
      }else{
        setErrorMsg(validationErrMsg);
        setIsError(true);
    }
    }
    const validateInputs = () => {
      setSuccess(false);
      setIsError(false);
      const boolEmail = email.trim() !== "" && email.trim().match(regEmail) ? true : false;
      const boolName = name.trim() !== "" ? true : false;
      const boolToken = (token !== null && token !== "")  ? true : false;
      setNameError(!boolName);
      setEmailError(!boolEmail);
  
      return boolEmail && boolName && boolToken;
    }

  return (
    <React.Fragment>
        <Box sx={{width:'80%', margin:'5% auto', }}>
        <Stack direction={{ xs: 'column', md: 'row' }} width="100%" spacing={{ xs: 3, md: 14}} >
            <Box width={{xs:'319px', md:'480px'}} height={{xs: '203px', md:'270px'}} sx={{ color:'#9c9c9c', fontSize:'15px !important', fontWeight:'400', 
            }}>             
                <iframe className="video-break" id="quote-video" src="https://player.vimeo.com/video/236168605" 
                   style={{width:'100%', height:'100%'}} 
                   frameBorder="0" allowFullScreen="yes">                    
                </iframe>
            </Box>
            <Box sx={{color:'#9c9c9c', fontSize:'15px !important', fontWeight:'400'}}>             
                <form style={{width:'100%'}} name="quoteForm" id="quoteForm" action="../quote.php" method="post">
                    <Stack spacing={2} direction="column" sx={{width:'100%', alignItems:"right"}}>
                        <Typography variant="h5" fontSize={{xs:'1.1rem', md: '1.5rem'}} sx={{fontWeight:'700', color:'#464646 !important;'}} >Learn why the cloud is a must have.</Typography>
                        <TextField value={name} name="name" error={nameError} id="outlined-error" label="Name" required  size='small' onChange={(e) => setName(e.target.value)}/>
                        <TextField value={email} name="email" error={emailError} id="outlined-error" label="Email" required size='small' onChange={(e) => setEmail(e.target.value)}/>
                        <TextField value={business} name="business" label="Organization/Business" size='small' onChange={(e) => setBusiness(e.target.value)}/>
                        <TextField value={network} name="network" label="Number of unique network users" type='number' InputProps={{ inputProps: { min: 0 } }} size='small' onChange={(e) => setNetwork(e.target.value)}/>
                        <TextField value={noOfEmail} name="noOfEmail" label="Number of email user addresses" type='number' InputProps={{ inputProps: { min: 0 } }} size='small' onChange={(e) => setNoOfEmail(e.target.value)}/>
                        <TextField value={noOfOffice} name="noOfOffice" label="Number of Office (Word, Excel, PowerPoint) users" InputProps={{ inputProps: { min: 0 } }} type='number' size='small' onChange={(e) => setNoOfOffice(e.target.value)}/>
                        <TextField value={noOfWS} name="noOfWS" label="Total number of Workstations connecting to the cloud" InputProps={{ inputProps: { min: 0 } }} type='number' size='small' onChange={(e) => setNoOfWS(e.target.value)}/>
                        <TextField value={noOfMobile} name="noOfMobile" label="Total number of Mobile devices connecting to the cloud" InputProps={{ inputProps: { min: 0 } }} type='number' size='small' onChange={(e) => setNoOfMobile(e.target.value)}/>
                        <TextField value={message} name="message" label="Additional Message" multiline rows={10} onChange={(e) => setMessage(e.target.value)}/>
                        <Box textAlign={'left'}>
                            <HCaptcha
                                sitekey="26fad0e6-1068-4bee-9193-3e095e48dd5f"
                                onVerify={setToken}
                                ref={captchaRef}
                            />
                            <Typography sx={{marginTop:'15px', color:'green', display:success ? 'block' : 'none'}}>Thank you for your message. It has been sent.</Typography>
                            <Typography sx={{marginTop:'15px', color:'red', display:isError ? 'block' : 'none'}}>{errMsg}</Typography>
                        </Box>
                        <Button disabled={disableButton} variant="contained" sx={{maxWidth:"150px"}} onClick={handleSubmit}>SUBMIT RFQ</Button>
                    </Stack>
                </form>
            </Box>
        </Stack>
        </Box>
    </React.Fragment>
  )
}

export default GetAQuote