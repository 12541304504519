import React from 'react'
import PageStructure from '../components/shared/PageStructure';
import ThankyouComp from '../components/ThankyouComp';

const ThankyouPage = () => {
  return (
    <PageStructure text={'Thank You'} page={ThankyouComp} />
  )
}

export default ThankyouPage;
