
import React, { useState } from "react";
import {
  Drawer,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { pages } from "../utilities/util";


const DrawerComp = ({menuClick}) => {
    const [openDrawer, setOpenDrawer] = useState(false);
    const handleMenuClick = (index) =>{
      menuClick(index);
      setOpenDrawer(false);
    }
    return (
      <>
        <Drawer
          anchor="left"
          open={openDrawer}
          onClose={() => setOpenDrawer(false)}
        >
          <List>
            {pages.map((page, index) => (
              <ListItemButton key={index}>
                <ListItemIcon>
                  <ListItemText onClick={() => handleMenuClick(index)}>{page}</ListItemText>
                </ListItemIcon>
              </ListItemButton>
            ))}
          </List>
        </Drawer>
        <IconButton
          sx={{ color: "black", marginLeft: "auto" }}
          onClick={() => setOpenDrawer(!openDrawer)}
        >
          <MenuIcon color="white" />
        </IconButton>
      </>
    );
}

export default DrawerComp