import React from 'react'
import WhatIsTheCloudCom from '../../components/faq/WhatIsTheCloudCom'
import PageStructure from '../../components/shared/PageStructure'


const WhatIsTheCouldPage = () => {
  return (
    
    <PageStructure text={'WHAT IS THE CLOUD?'} page={WhatIsTheCloudCom} />
  )
}

export default WhatIsTheCouldPage