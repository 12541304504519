import React, { useState } from "react";
import {
  AppBar,
  Tab,
  Tabs,
  Toolbar,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import DrawerComp from "./DrawerComp";
import { pages } from "../utilities/util";
import { Link } from "react-router-dom";
const Header = ({menuClick}) => {
    const [value, setValue] = useState(0);
    const theme = useTheme();
    const isMatch = useMediaQuery(theme.breakpoints.down("md"));
  
    return (
      <React.Fragment>
        <AppBar sx={{ background: "#fff" }}>
          <Toolbar sx={{color:"black"}}>
            <Link to="/">
              <img src="/assets/images/logo/lmw-logo.jpg" />
            </Link>
            {isMatch ? (
              <>                
                <DrawerComp menuClick={menuClick}/>
              </>
            ) : (
              <>
                <Tabs
                  sx={{ marginLeft: "auto" }}
                  indicatorColor="secondary"
                  textColor="inherit"
                  value={value}
                  onChange={(e, value) => setValue(value)}
                >
                {pages.map((page, index) => (
                    <Tab key={index} label={page} onClick={() => menuClick(index)} />
                  ))}
                </Tabs>
                
              </>
            )}
          </Toolbar>
        </AppBar>
      </React.Fragment>
    );
}

export default Header