
import { Box, Button, Stack, Typography, Link } from '@mui/material';
import React from 'react'

const MoveMeTheCloudComp = () => {
  return (
    <Box sx={{backgroundColor:"#f6f6f6 !important", width:"100%", marginTop:"30px",textAlign:"center"}}>
        <Box sx={{padding:"3% 10%"}}>
            <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                <Stack direction="column"  spacing={2} textAlign={'left'} className='textColor fontF'>
                    <Typography variant='subtitle2'>
                        We believe there’s a better way to run your business in the cloud.
                    </Typography>
                    <Typography variant='subtitle2'>
                        There are plenty of good reasons, but the main takeaway is this: moving to the cloud will make your business better, more nimble, efficient and secure.
                    </Typography>
                    <Typography variant='subtitle2'>
                        The cherry on top? You no longer have to maintain server hardware, backups or plan for costly upgrades.
                    </Typography>
                    <Link href="/move-me-to-the-cloud/">
                        <Button variant='contained'sx={{width:"150px"}} >WATCH VIDEO</Button>
                    </Link>
                </Stack>
                <Box>
                    <Link href="/move-me-to-the-cloud/">
                        <img style={{aspectRatio:"auto 615 / 348", width:"615", height:"auto", maxWidth:"100%"}} src="/assets/images/mmtcloud/watch-move-me-to-the-cloud.jpg"
                            srcSet="/assets/images/mmtcloud/watch-move-me-to-the-cloud.jpg 615w, /assets/images/mmtcloud/watch-move-me-to-the-cloud-300x170.jpg 300w"
                            sizes="(max-width: 615px) 100vw, 615px"
                        />
                    </Link>
                    
                </Box>
            </Stack>
        </Box>
    </Box>
  )
}

export default MoveMeTheCloudComp