import { Box, Typography } from '@mui/material'
import React from 'react'

const PageStructure = ({page:Page, text: Text}) => {
  return (
    
    <Box sx={{marginTop:'64px', width:'100%'}}>
        <Box sx={{background:"url(/assets/images/other/concrete_1600x750.jpg)",
            backgroundRepeat:"no-repeat", backgroundPosition:"50% 61.5%", width:'100%', height:'100%'}}>
            <Typography height={{xs : '100px', md:'200px'}} fontSize={{xs:'24px', md: '42px'}} style={{lineHeight:'1.3',
             color:'#fff', display:'flex', justifyContent:'center', alignItems:'center', top:0, left:0}}
            >{Text}</Typography>
        </Box>
        <Box sx={{width:'100%'}}>
          <Page />
        </Box>
    </Box>
  )
}

export default PageStructure