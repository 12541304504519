import { Box, Button, Stack, Typography, Link, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';

const LearnWhyTheCloud = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  return (
    <Box sx={{backgroundColor:"#f6f6f6 !important", width:"100%", marginTop:"30px"}}>
        <Box sx={{padding:"3% 10%"}}>
            <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} sx={{justifyContent:"center"}}>
                <Typography variant='h5' className='sectionHeader' sx={{fontSize: isMobile ? '20px !important' : ''}}>
                    Learn why the cloud is a must have for your business.
                </Typography>
                <Box sx={{textAlign:'center'}}>
                    <Link href="/get-a-quote/">
                        <Button variant="contained" sx={{minWidth:"132px", maxWidth:"132px"}}>
                            GET A QUOTE
                        </Button>
                    </Link>
                </Box>
            </Stack>
        </Box>
    </Box>
  )
}

export default LearnWhyTheCloud