import { Typography } from '@mui/material'
import { Stack } from '@mui/system'
import React from 'react'

const ClientSayItem = ({item}) => {
  return (
    <Stack>
        <Typography variant='body2' sx={{letterSpacing: "0.02em", lineHeight:2, fontSize:"16px"}}>
            {item.content}
        </Typography>
        <Typography sx={{marginTop:"20px"}}>
            {`${item.clientName} - ${item.position}`}
        </Typography>
    </Stack>
  )
}

export default ClientSayItem