
import React from 'react'
import { Paper, useMediaQuery, useTheme  } from '@mui/material'

const Item = ({item}) => {
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.only("xs"));
  return (
    <Paper sx={{boxShadow:'none', height:'70vh', width:'100%'}}>
        <img  src={isMatch? item.mobileImg : item.image} alt={item.title} style={{height:"inherit", width:'inherit', objectFit:"fill"}} />
    </Paper>
  )
}

export default Item