import React from 'react'
import MoveMeToTheCloudPageCom from '../components/MoveMeToTheCloudPageCom'
import PageStructure from '../components/shared/PageStructure'

const MoveMeToTheCloudPage = () => {
  return (
    <PageStructure text={'MOVE ME TO THE CLOUD'} page={MoveMeToTheCloudPageCom} />
  )
}

export default MoveMeToTheCloudPage