import { Box, Card, CardContent, Stack, Typography, useMediaQuery, useTheme, } from '@mui/material';
import CloudOutlinedIcon from '@mui/icons-material/CloudOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import RingVolumeOutlinedIcon from '@mui/icons-material/RingVolumeOutlined';
import DesktopWindowsOutlinedIcon from '@mui/icons-material/DesktopWindowsOutlined';

import {Link} from 'react-router-dom';
import React, { useState } from 'react'
import LinkCtr from './shared/LinkCtr';

const WhatWeDo = () => {
    const theme = useTheme();
    const isMatch = useMediaQuery(theme.breakpoints.down("md"));

    
    const LinkCtrLocal = () => {        
        return(
            <Box sx={{fontFamily: "Open Sans,Arial,sans-serif !important", fontWeight:700, fontSize:"13px !important"}}>
                <LinkCtr text="READ MORE" toUrl={"/what-we-do"} />
            </Box>
        )
    }
  return (
    <Stack direction='column' sx={{padding:"3% 10%", marginTop:'30px'}}>
        <Stack sx={{ width:"100%", textAlign:"center"}} spacing={2}>
            <Typography variant='h5' className='sectionHeader'  sx={{fontFamily:"Open Sans,Arial,sans-serif;"}}>
                WHAT WE DO
            </Typography>
            <Typography variant='body2' className='textColor'>
                We handle the techie stuff so you can focus on running your business.
            </Typography>            
        </Stack>
        <Box sx={{width:'100%', textAlign:'center', display:'flex', justifyContent:'center', marginTop:"30px"}}>
            <Stack spacing={4} direction={{ xs: 'column', md: 'row' }}>
                <Card sx={{width: {isMatch} ? '100%': '20%'}}>
                    <CardContent sx={{height:"100%"}}>
                        <Stack direction={"row"} spacing={2} height={"100%"}>
                            <CloudOutlinedIcon fontSize='large' style={{color:"#03a9f4"}} />
                            <Stack justifyContent={"space-around"} textAlign="left" height={"100%"}>
                                <Stack spacing={2}>
                                    <Typography variant='h5' className='cardHeader'>Virtual Servers</Typography>
                                    <Typography variant='body2' className='textColor line2'>Managing on-prem servers is inefficient and a hassle. Imagine no longer having to maintain a physical server and not having to worry about downtime, security, or data recovery.</Typography>
                                </Stack>
                                <Box marginBottom={"20px"}>
                                    <LinkCtrLocal />
                                </Box>
                            </Stack>
                        </Stack>
                        
                    </CardContent>
                </Card>
                <Card sx={{width: {isMatch} ? '100%': '20%'}}>
                    <CardContent sx={{height:"100%"}}>
                         <Stack direction={"row"} spacing={2} height={"100%"}>
                            <EmailOutlinedIcon fontSize='large' style={{color:"#03a9f4"}} />
                            <Stack justifyContent={"space-around"} textAlign="left" height={"100%"}>
                                <Stack spacing={2} >
                                    <Typography variant='h5' className='cardHeader'>Hosted Mail</Typography>
                                    <Typography variant='body2' className='textColor line2'>Cloud solutions have emerged as a viable option for running IT applications. Upgrades are automatic and maintenance seamless, freeing IT to work on higher value projects.</Typography>                                
                                </Stack>
                                <Box marginBottom={"20px"}>
                                    <LinkCtrLocal />
                                </Box>
                            </Stack>
                        </Stack>
                    </CardContent>
                </Card>
                <Card sx={{width: {isMatch} ? '100%': '20%'}}>
                    <CardContent sx={{height:"100%"}}>
                        <Stack direction={"row"} spacing={2} height={"100%"}>
                            <InsertDriveFileOutlinedIcon fontSize='large' style={{color:"#03a9f4"}}/>
                            <Stack justifyContent={"space-around"} textAlign="left" height={"100%"}>
                                <Stack spacing={2}>
                                    <Typography variant='h5' className='cardHeader'>Document Storage</Typography>
                                    <Typography variant='body2' className='textColor line2'>The cloud has virtually unlimited storage. Storing your documents in the cloud removes the need for every user to hold a personal copy of documents and all users know they have the current version.</Typography>                                    
                                </Stack>
                                <Box sx={{marginBottom:'20px'}}>
                                    <LinkCtrLocal />
                                </Box>
                            </Stack>
                        </Stack>
                    </CardContent>
                </Card>                
            </Stack>
        </Box>
        <Box sx={{width:'100%', textAlign:'center', display:'flex', justifyContent:'center', marginTop:"30px"}}>
            <Stack spacing={4} direction={{ xs: 'column', md: 'row' }}>
                <Card sx={{width: {isMatch} ? '100%': '20%'}}>
                    <CardContent sx={{height:"100%"}}>
                        <Stack direction={"row"} spacing={2} height={"100%"}>
                            <CalendarMonthOutlinedIcon fontSize='large' style={{color:"#03a9f4"}}/>
                            <Stack justifyContent={"space-around"} textAlign="left" height={"100%"}>
                                <Stack spacing={2} textAlign="left">
                                <Typography variant='h5' className='cardHeader'>Office 365</Typography>
                                <Typography variant='body2' className='textColor line2'>Microsoft takes care of all the dirty work so you don’t have to: updates, patches, and upgrades just happen in the background without going workstation to workstation.</Typography>                                
                                </Stack>
                                <Box sx={{marginBottom:'20px'}}>
                                    <LinkCtrLocal />
                                </Box>
                            </Stack>
                            
                        </Stack>
                    </CardContent>
                </Card>
                <Card sx={{width: {isMatch} ? '100%': '20%'}}>
                    <CardContent sx={{height:"100%"}}>
                         <Stack direction={"row"} spacing={2} height={"100%"}>
                            <RingVolumeOutlinedIcon fontSize='large' style={{color:"#03a9f4"}}/>
                            <Stack justifyContent={"space-around"} textAlign="left" height={"100%"}>
                                <Stack spacing={2} textAlign="left">
                                    <Typography variant='h5' className='cardHeader'>Telephony</Typography>
                                    <Typography variant='body2' className='textColor line2'>If you are thinking about moving your on-prem file servers to the cloud, why not your in-house telephone system too? Telephony means no more costly PBX maintenance or upgrades.</Typography>
                                </Stack>
                                <Box sx={{marginBottom:'20px'}}>
                                    <LinkCtrLocal />
                                </Box>
                            </Stack>
                            
                        </Stack>
                    </CardContent>
                </Card>
                <Card sx={{width: {isMatch} ? '100%': '20%'}}>
                    <CardContent sx={{height:"100%"}}>
                        <Stack direction={"row"} spacing={2}  height={"100%"}>
                            <DesktopWindowsOutlinedIcon fontSize='large' style={{color:"#03a9f4"}}/>
                            <Stack justifyContent={"space-around"} textAlign="left" height={"100%"}>
                                <Stack spacing={2} textAlign="left">
                                    <Typography variant='h5' className='cardHeader'>Workstations</Typography>
                                    <Typography variant='body2' className='textColor line2'>By moving your infrastructure to the cloud, you simplify the workstations and lower the cost required to connect to your business applications. We help you put the entire solution together.</Typography>
                                </Stack>
                                <Box sx={{marginBottom:'20px'}}>
                                    <LinkCtrLocal />
                                </Box>
                            </Stack>
                        </Stack>
                    </CardContent>
                </Card>                
            </Stack>
        </Box>
    </Stack>
  )
}

export default WhatWeDo