import { Link, Stack, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React, { useState } from 'react'
import CopyRight from './CopyRight'

const Footer = () => {
  const LinkCtr = ({toUrl, text}) =>{

    const [isHover, setIsHover] = useState(false);
    const handleMouseEnter = () => {
        setIsHover(true);
     }; 
     const boxStyle = {
        color: isHover ? '#e2e2e2' : '#7e7e7e',
        textDecoration: 'none',
        cursor: 'pointer',
        fontFamily: 'Open Sans,Arial,sans-serif',
        fontSize: '14px',
        fontWeight: '300',
     };        
    const handleMouseLeave = () => {
        setIsHover(false);
    };
    return(
        <Link href={toUrl} style={boxStyle}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >{text}</Link> 
    )
  }
  const title = {
    marginBottom: "10px",
    fontFamily: 'Open Sans,Arial,sans-serif',
    fontSize: '14px',
    fontWeight: '600',
    color: '#e2e2e2',
  }  
  return (
    <Box>
        <Box sx={{backgroundColor:"#222325", padding:"60px 0 30px", color:"#7e7e7e"}}>
            <Stack direction={{ xs: 'column', md: 'row' }} spacing={{xs: 4, md: 12}} justifyContent="center" alignItems={'top'} textAlign={{xs: 'center', md: 'left'}}>
                <Stack spacing={1}>
                    <Typography style={title}>LEARN MORE</Typography>
                    <LinkCtr toUrl={"/faq/what-is-the-cloud"} text={"What is the cloud?"} />
                    <LinkCtr toUrl={"/faq/is-the-cloud-secure"} text={"Is the cloud secure?"} />
                    <LinkCtr toUrl={"/faq/is-the-cloud-reliable"} text={"Is the cloud reliable?"} />
                    <LinkCtr toUrl={"/faq/what-does-the-cloud-cost"} text={"What does the cloud cost?"} />
                    <LinkCtr toUrl={"/what-we-do"} text={"What We Do"} />
                    <LinkCtr toUrl={"/move-me-to-the-cloud"} text={"Move Me to the Cloud"} />
                </Stack>
                <Stack spacing={1}>
                    <Typography style={title}>CONTACT US</Typography>
                    <Typography variant='body2'>Cloud Service Geeks</Typography>
                    <LinkCtr toUrl={"http://striglia.net/"} text={"Striglia Enterprises, LLC"} />
                    <Typography variant='body2'>Palm City, FL 34990</Typography>
                    <Typography variant='body2'>email:<LinkCtr toUrl={"mailto:info@cloudservicegeeks.com"} text={"info@cloudservicegeeks.com"} /></Typography>
                    <Typography variant='body2'>Office: (772) 888-3888</Typography>
                    <Box>
                        <a href="https://www.facebook.com/Cloud-Service-Geeks-111360097089774" target="_blank" rel="noopener"><img decoding="async" loading="lazy" className="alignnone wp-image-1977" src="/assets/images/facebook/Facebook-Icon.png" alt="Facebook" width="35" height="35" srcSet="/assets/images/facebook/Facebook-Icon.png 128w, /assets/images/facebook/Facebook-Icon-100x100.png 100w" sizes="(max-width: 35px) 100vw, 35px" /></a>
                    </Box>
                </Stack>
            </Stack>
        </Box>
        <CopyRight />
    </Box>
  )
}

export default Footer