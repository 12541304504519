import React from 'react'
import PageStructure from '../components/shared/PageStructure'
import WhatWeDoTabs from '../components/WhatWeDoTabs'

const WhatWeDoPage = () => {
  return (
    <PageStructure text={"WHAT WE DO"} page={WhatWeDoTabs} />
  )
}

export default WhatWeDoPage