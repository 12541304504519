
import { Box, Card, CardContent, Stack, Typography, useMediaQuery, useTheme, } from '@mui/material';
import UpdateOutlinedIcon from '@mui/icons-material/UpdateOutlined';
import CloudIcon from '@mui/icons-material/Cloud';
import StorageOutlinedIcon from '@mui/icons-material/StorageOutlined';
import LockIcon from '@mui/icons-material/Lock';
import React from 'react'

const WhyTheCloudComp = () => {
    const theme = useTheme();
    const isMatch = useMediaQuery(theme.breakpoints.down("md"));
    const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  return (
    <Stack direction='column' sx={{fontFamily:"Open Sans,Arial,sans-serif;", padding:"3% 10%"}}>
        <Stack sx={{ textAlign:"center"}} spacing={2}>
            {isMobile &&
                <>
                    <Typography variant='body2' className='textColor'>We’re here to help you move your business to the cloud.</Typography>
                    <Typography variant='body2' className='textColor'>(And make your life a whole lot easier.)</Typography>
                </>
            }
            <Typography variant='h2' className='sectionHeader' component='div' sx={{fontFamily:"Open Sans,Arial,sans-serif;"}}>
                FOUR GOOD REASONS TO MOVE TO THE CLOUD
            </Typography>
            <Typography variant='body2' className='textColor'>
                There are a lot of reasons to move to the cloud, but below are some of our favorites:
            </Typography>
        </Stack>
        <Box sx={{textAlign:'center', display:'flex', justifyContent:'center', marginTop:"30px"}}>
            <Stack spacing={4} direction={{ xs: 'column', md: 'row' }}>
                <Card sx={{width: {isMatch} ? '100%': '20%'}}>
                    <CardContent>
                        <Box sx={{display:'flex', justifyContent:'center'}}>                        
                            <Typography component={'div'} sx={{width:"60px", height:"60px", borderRadius:"50%", backgroundColor:"#03a9f4", marginBottom:'10px'}}>
                                <UpdateOutlinedIcon fontSize='large' style={{color:"white", fontSize:"48px", marginTop:"5px", marginLeft:"2px" }} />
                            </Typography>
                        </Box>
                        <Typography variant='h5' className='cardHeader'>
                            No downtime
                        </Typography>
                        <Typography className='textColor line2' variant='body2' sx={{marginTop:"15px", textAlign:'left'}}>
                            We deliver 99.9936% uptime. Can your local equipment do the same? Keep employees productive and your business up and running without disruptions. Grab a cup of coffee and get back to work.
                        </Typography>
                    </CardContent>
                </Card>
                <Card sx={{width: {isMatch} ? '100%': '20%'}}>
                    <CardContent>
                        <Box sx={{display:'flex', justifyContent:'center'}}>                        
                            <Typography component={'div'} sx={{width:"60px", height:"60px", borderRadius:"50%", backgroundColor:"#03a9f4", marginBottom:'10px'}}>
                                <CloudIcon fontSize='large' style={{color:"white", fontSize:"44px", marginTop:"6px", marginLeft:"2px" }} />
                            </Typography>
                        </Box>
                        <Typography variant='h5' component='div' className='cardHeader'>
                            Fits just right
                        </Typography>
                        <Typography className='textColor line2' variant='body2' sx={{marginTop:"15px", textAlign:'left'}}>
                        Need to scale your server up/down? No migration. No need to reinstall programs. Turn it up when you need more, scale it back when you need less. Custom tailored just for you.
                        </Typography>
                    </CardContent>
                </Card>
                <Card sx={{width: {isMatch} ? '100%': '20%'}}>
                    <CardContent>
                        <Box sx={{display:'flex', justifyContent:'center'}}>                        
                            <Typography component={'div'} sx={{width:"60px", height:"60px", borderRadius:"50%", backgroundColor:"#03a9f4", marginBottom:'10px'}}>
                                <StorageOutlinedIcon fontSize='large' style={{color:"white", fontSize:"42px", marginTop:"8px", marginLeft:"2px" }} />
                            </Typography>
                        </Box>
                        <Typography variant='h5' component='div' className='cardHeader'>
                            Easy peasy
                        </Typography>
                        <Typography className='textColor line2' variant='body2' sx={{marginTop:"15px", textAlign:'left'}}>
                            Always have the latest and greatest software. We install new releases and security updates automatically. And to top it off, we make sure your data is backed up – without you lifting a finger.
                        </Typography>
                    </CardContent>
                </Card>
                <Card sx={{width: {isMatch} ? '100%': '20%'}}>
                    <CardContent>
                        <Box sx={{display:'flex', justifyContent:'center'}}>                        
                            <Typography component={'div'} sx={{width:"60px", height:"60px", borderRadius:"50%", backgroundColor:"#03a9f4", marginBottom:'10px'}}>
                                <LockIcon fontSize='large' style={{color:"white", fontSize:"42px", marginTop:"8px", marginLeft:"2px" }} />
                            </Typography>
                        </Box>    
                        <Typography variant='h5' component='div' className='cardHeader'>
                            Peace of mind
                        </Typography>
                        <Typography className='textColor line2' variant='body2' sx={{marginTop:"15px", textAlign:'left'}}>
                            The cloud is more secure and more reliable than traditional local hardware. You can sleep better knowing that your data is stored in world-class, secure, data centers. Rest easy.
                        </Typography>
                    </CardContent>
                </Card>
            </Stack>
        </Box>
    </Stack>
    
  )
}

export default WhyTheCloudComp