import React from 'react'
import IsTheCloudReliable from '../../components/faq/IsTheCloudReliable'
import PageStructure from '../../components/shared/PageStructure'

const IsTheCloudReliablePage = () => {
  return (
    <PageStructure text={'WHAT IS THE CLOUD?'} page={IsTheCloudReliable} />
  )
}

export default IsTheCloudReliablePage