import React from 'react';
import PropTypes from 'prop-types';
import { Box, Tabs, Tab, Typography, Stack } from '@mui/material';
import { useState } from 'react'
import LinkCtr from './shared/LinkCtr';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Box sx={{p:3}}
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
    {children}
    </Box>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


const WhatWeDoTabs = () => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{width:'100%', display:'flex', flexDirection:'column', alignItems:'center'}}>      
      <Box sx={{width:'100%', textAlign:'center', display:'flex', justifyContent:'center',  maxWidth: { xs: 320, sm: 600, md:800, lg:1100, xl:1400 }, bgcolor: 'background.paper'}}>
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          allowScrollButtonsMobile
        >
            <Tab label='Virtual Servers' {...a11yProps(0)}/>
            <Tab label='Hosted Mail' {...a11yProps(1)}/>
            <Tab label='Document Storage' {...a11yProps(2)}/>
            <Tab label='Office 365' {...a11yProps(3)}/>
            <Tab label='Telephony' {...a11yProps(4)}/>
            <Tab label='Workstations'  {...a11yProps(5)}/>
          </Tabs>
        </Box>
        <Box sx={{marginLeft:'10%', marginRight:'10%', marginBottom:'3%', color:'#9c9c9c', fontSize:'15px !important', fontWeight:'400'}}>
          <TabPanel value={value} index={0}>
            <Stack spacing={2}>
              <Typography>
                We take the hassle out of managing your servers, so you can focus on your business. As a certified &nbsp;  
                <a className='link' href="https://azure.microsoft.com/en-us/overview/what-is-azure/" target="_blank" >Microsoft Azure</a> partner, our Virtual Servers give you efficiencies and capabilities that are just not available with on-prem servers. 
              </Typography>
              <Stack spacing={1}>
                <Typography sx={{fontWeight:'700'}} >99.9% uptime</Typography>
                <Typography>Gone are the days of server failures and unplanned outages.  With a 99.96% uptime guarantee, you have the peace of mind your system will run smoothly so your team and your business can keep running.</Typography>
              </Stack>
              <Stack spacing={1}>
                <Typography sx={{fontWeight:'700'}} >Scalability</Typography>
                <Typography>Upgrade or downgrade your server on a moment’s notice without costly software reinstallation and reconfiguration.  Want to increase the speed of your server by doubling the memory and adding cores? That’s only a 15 minute process with a virtual server – unlike the days it could take with an on-prem  server.  Even better, there are no major capital expenditure!</Typography>
              </Stack>
              <Stack spacing={1}>
                <Typography sx={{fontWeight:'700'}} >Improved disaster recovery</Typography>
                <Typography>We make redundant back-ups – at the file level, as well as a complete image backup.  You don’t need to worry that a fire, flood or theft will disable your entire system.  If the worst happens to your office, you can be up and running in an alternate location in the time it takes you to plug in a new workstation. (
                <LinkCtr toUrl="/faq/is-the-cloud-secure" withoutHoverColor='#000000' text='See our FAQ – Is the cloud secure' />)
                </Typography>
              </Stack>
              <Stack spacing={1}>
                <Typography sx={{fontWeight:'700'}} >Extend the life of older applications</Typography>
                <Typography>Have an old legacy application (or two) still running in your environment? Chances are you struggle keeping it running with newer operating systems or new hardware.  Most likely the person or company who created it is no longer providing updates for it, and of course, your IT department is afraid to touch it!</Typography>
                <Typography>Virtual Servers remove compatibility issues by providing application isolation. That means you can extend the application’s life, maintain uptime, and finally get rid of that old machine hidden in the corner of your server room.</Typography>
              </Stack>
              <Stack spacing={1}>
                <Typography sx={{fontWeight:'700'}} >Redundancy</Typography>
                <Typography>
                  Having multiple servers run all the same applications is a major requirement for many organizations.  With virtual servers, you can practice redundancy without spending a ton of time and  money on extra hardware, maintenance and backups.  Redundant virtual servers are always created on different physical servers, and optionally – in different datacenters, so in the unlikely event that one server fails, a redundant server would immediately take its place.  For businesses, this means more security for less capital expenditure, and more uptime with less interruption of service.                
                </Typography>
              </Stack>
              <Stack spacing={1}>
                <Typography sx={{fontWeight:'700'}} >Pay as you go</Typography>
                <Typography>Pay for what you use, just like your utility bill!  No more capital expense for new hardware.</Typography>
                <Typography>We build your server tailored for your needs (RAM, disk space, processor, etc.), with no start-up costs.  It’s flexible so, as your needs change, you can easily scale your system up (or down) – without penalties. This allows you to manage your IT costs more easily, and more effectively.</Typography>
                <Typography>Gone are the days of designing (and paying for) a server you hope will fit your needs for the next 5 years – only for it to be obsolete soon after installation!</Typography>
                <Typography><LinkCtr toUrl="/get-a-quote" withoutHoverColor='#000000' text='Contact us' /> to get a virtual server custom tailored for you –– with  scalability you won’t outgrow!</Typography>
              </Stack>
              <Stack sx={{alignItems:'center', marginTop:'5% !important'}}>
                <Typography component={'p'}>
                  <a href="http://azure.microsoft.com/" target="_blank" rel="noopener">
                    <img decoding="async"  src="/assets/images/azure/azurecertified.png" alt="" width="203" height="203" srcSet="/assets/images/azure/azurecertified.png 203w, /assets/images/azure/azurecertified-150x150.png 150w, /assets/images/azure/azurecertified-100x100.png 100w" sizes="(max-width: 203px) 100vw, 203px" />
                  </a>
                </Typography>
              </Stack>
            </Stack>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Stack spacing={2}>
              <Typography>Cloud solutions have emerged as a viable option for running most IT applications, and e-mail is no exception. Whether you want a single mailbox or a dedicated email server, you’ll get all the benefits of business-class email without the headache of running it in-house. Not to mention, upgrades are automatic and maintenance is seamless – freeing your IT team to work on more important projects.</Typography>
              <Stack>
                <Typography sx={{fontWeight:'700'}} >Choose the service that’s right for you</Typography>
                <Typography component={'div'}>
                  <ul>
                    <li>Start with as little as one mailbox, and add to it as your business grows</li>
                    <li>Or opt for your own dedicated server, with an unlimited number of mailboxes, calendar, and task-management tools</li>
                    <li>Simple, reasonable pricing for easier cost management</li>
                  </ul>
                </Typography>
              </Stack>
              <Stack>
                <Typography sx={{fontWeight:'700'}} >We’ll take care of the details</Typography>
                <Typography component={'div'}>
                  <ul>
                    <li>Setting up the mailboxes or server</li>
                    <li>Exporting old emails and importing them into the hosted service</li>
                    <li>Simple, reasonable pricing for easier cost management</li>
                  </ul>
                </Typography>
              </Stack>
              <Typography><LinkCtr toUrl="/get-a-quote" withoutHoverColor='#000000' text='Contact us' /> to get more information on how easy it is to host your email in the cloud!</Typography>
            </Stack>        
          </TabPanel>
          <TabPanel value={value} index={2}>
            <Stack spacing={2}>
              <Typography>Make your file sharing and storage easier and more cost-effective ––let us store your business data in the <a href="http://www.webopedia.com/TERM/C/cloud.html" target="_blank" className='link'>cloud</a>.  It is a simple and scalable way to store, access, and share data over the Internet.</Typography>
              <Stack>
                <Typography sx={{fontWeight:'700'}} >Stronger Security</Typography>
                <Typography>Strong protection for data backup, archival and disaster recovery</Typography>
              </Stack>
              <Stack>
                <Typography sx={{fontWeight:'700'}} >Easy Migration</Typography>
                <Typography>Migrating your data to Azure File storage––your applications can access it without code changes.</Typography>
              </Stack>
              <Stack>
                <Typography sx={{fontWeight:'700'}} >Flexible & Scalable</Typography>
                <Typography>Scale your storage on-the-fly as your needs change with virtually unlimited storage capability.</Typography>
              </Stack>
              <Stack>
                <Typography sx={{fontWeight:'700'}} >Lower Costs</Typography>
                <Typography>Take advantage of lower cloud storage costs, and walk away from on-prem file server management of hardware, capacity, and budget.</Typography>
              </Stack>
              <Stack>
                <Typography sx={{fontWeight:'700'}} >Accessible Anytime, Anywhere</Typography>
                <Typography>Get access to your documents from anywhere, at anytime, on any internet-connected device.  Flexibility at its finest!</Typography>
              </Stack>
              <Stack>
                <Typography sx={{fontWeight:'700'}} >Smarter Storage</Typography>
                <Typography>Employees no longer need to hold personal copies of documents and can always know they have they have the latest document version.</Typography>
                <Typography>Sound too good to be true?  <LinkCtr toUrl="/get-a-quote" withoutHoverColor='#000000' text='Contact us' /> and we will show you how it’s done!</Typography>
              </Stack>
            </Stack>
          </TabPanel>
          <TabPanel value={value} index={3}>
            <Stack spacing={2}>
              <Typography>Think of Microsoft Office 365 as ‘your complete office, in the cloud’.  Get your favorite Microsoft Office personal productivity tools like Outlook, Word, Excel, etc. on your work computer, and anywhere online!   Plus you and your team will always have the latest versions — updates, patches, and upgrades happen automatically — for less than what you’d typically spend buying the software alone!</Typography>
              <Typography>As a Microsoft Certified Partner, we handle the migration, installation and configuration of Office 365 across all your systems and devices, and are there to support you after the process.</Typography>
              <Stack>
                <Typography sx={{fontWeight:'700'}} >Need more persuading?</Typography>
                <Typography>Check out the Forrester Total Economic Impact™ of Microsoft Office 365 report and learn how it can help you in the following ways: 
                &nbsp; (<a className='link' href="https://resources.office.com/ww-landing-forrester-tei-of-microsoft-o365-study-report.html" target="_blank" rel="noopener">download report here</a>)
                </Typography>
                <Typography component={'div'}>
                  <ul>
                    <li>Setting up the mailboxes or server</li>
                    <li>Exporting old emails and importing them into the hosted service</li>
                    <li>Simple, reasonable pricing for easier cost management</li>
                  </ul>
                </Typography>
              </Stack>
              <Stack>
                <Typography sx={{fontWeight:'700'}} >Enterprise-grade security</Typography>
                <Typography>
                Built-in security plus compliance and privacy controls help keep your data safe.  Rest assured!  Office 365 is a security-hardened service, designed following the&nbsp;
                <a className='link' href="https://go.microsoft.com/fwlink/p/?LinkID=401245" target="_blank" rel="noopener">Microsoft Security Development Lifecycle</a>
                &nbsp;It brings together the best practices from two decades of building enterprise software and managing online services to give you an integrated software-as-a-service solution.
                </Typography>
              </Stack>
              <Stack>
                <Typography sx={{fontWeight:'700'}} >Guaranteed access</Typography>
                <Typography>Microsoft recognizes their productivity apps are mission critical; using them is how work gets done. So they ensure a 99.9% uptime with a financially-backed guarantee. </Typography>
              </Stack>
              <Typography>
                Get Microsoft Office 365 – when and where you need it!&nbsp; 
                <LinkCtr toUrl="/get-a-quote" withoutHoverColor='#000000' text='Contact us' /> to get started.
              </Typography>
              <Stack sx={{alignItems:'center', marginTop:'5% !important'}}>
                <Typography component={'p'}>
                <a href="https://partner.microsoft.com/" target="_blank" rel="noopener">
                  <img decoding="async" loading="lazy" src="/assets/images/microsoft/microsoft-certified.png" alt="" width="177" height="94" />
                </a>
                </Typography>
              </Stack>
            </Stack>
          </TabPanel>
          <TabPanel value={value} index={4}>
            <Stack spacing={2}>
              <Typography>Voice over Internet Protocol (VoIP) is 100% cloud based technology that allows you to make calls using the broadband Internet rather than the conventional phone system.</Typography>
              <Typography>VoIP works better than a conventional phone system by:</Typography>
              <Typography component={'div'}>
                <ul>
                  <li>Eliminating costly PBX hardware, software, maintenance or upgrades</li>
                  <li>Significantly reducing calling costs</li>
                  <li>Seamlessly linking multiple locations for easy call routing</li>
                  <li>Making it simple to add or move extensions by just an easy change in software configuration, rather than complex re-wiring</li>
                  <li>Giving access to abundant, advanced, and useful communications features – making you and your employees better equipped for call management</li>
                  <li>Providing a personal handset – no matter where you are</li>
                  <li>Providing a phone number/extension anywhere</li>
                </ul>
              </Typography>
              <Typography>
               Cloud Service Geeks uses&nbsp;
               <a className='link' href="https://www.ringcentral.com/office/how-it-works.html" target="_blank" rel="noopener">RingCentral</a>
               &nbsp;Technology for cloud based telephone communications, including voice, fax, text, call handling, mobile apps, conferencing, and online meetings.  This phone system seamlessly integrates with Salesforce®, Oracle®, Zendesk, Desk, Box, Dropbox™, Google, and Microsoft®, providing you a comprehensive, enterprise-class phone system.
              </Typography>
              <Typography>For over a decade, RingCentral has invested in building the most advanced cloud communications platform in the market, so you can confidently move your communications to the cloud.  Their distributed, redundant network routes calls dynamically, while redundant data centers and production environments ensure continuity during outages.  And the cherry on top – they stand by their commitment to reliability with a financially backed 99.999% service-level agreement for qualified customers.</Typography>
              <Typography>
                We have used this system at Cloud Service Geeks for the past 3 years and it works great!&nbsp; 
                <LinkCtr toUrl="/get-a-quote" withoutHoverColor='#000000' text='Get in touch with us' /> to learn how we can help you move your phone system to the cloud!
              </Typography>
              <Stack sx={{alignItems:'center', marginTop:'5% !important'}}>
                <Typography component={'p'}>
                <a href="https://www.ringcentral.com/" target="_blank" rel="noopener">
                <img decoding="async" loading="lazy"  src="/assets/images/ringcentral/ring-central-partner.png" alt="" width="324" height="31" srcSet="/assets/images/ringcentral/ring-central-partner.png 324w, /assets/images/ringcentral/ring-central-partner-300x29.png 300w" sizes="(max-width: 324px) 100vw, 324px" />
                
                </a>
                </Typography>
              </Stack>
            </Stack>
          </TabPanel>
          <TabPanel value={value} index={5}>
            <Stack spacing={2}>
              <Typography>
                Virtual workstations (or virtual desktops) can be a huge time and cost saver for your business. 
              </Typography>
              <Typography>Because desktop environments (icons, wallpaper, windows, folders, toolbars, widgets, etc.) are stored remotely, on a server rather than on an on-prem PC – employees have access to their desktop via the internet — anytime, anywhere.</Typography>
              <Stack spacing={1}>
                <Typography sx={{fontWeight:'700'}} >Accessible Anytime, Anywhere</Typography>
                <Typography>Have access to your on-demand desktop anytime, anywhere, from any device.  All your employees need is an internet connection and they can get to work  – making your business more flexible and more productive!  Even if a workstation crashes – no problem – they can access their virtual desktop from any other workstation.</Typography>
              </Stack>
              <Stack spacing={1}>
                <Typography sx={{fontWeight:'700'}}>Save Time & Cut Costs</Typography>
                <Typography>We don’t need to install, update and patch applications, backup files, or scan for viruses on individual client devices – we manage it all centrally! With Virtual Workstations deploying a new operating system, software application updates or patches across your network is quick and seamless. Not to mention that you can easily roll back to a previous version of the desktop if there is a problem with an update. Imagine how drastically this will decrease your IT management and support costs.</Typography>
              </Stack>
              <Stack spacing={1}>
                <Typography sx={{fontWeight:'700'}}>Improved Security</Typography>
                <Typography>Since confidential data is not stored locally, Virtual Workstations are more secure.   If a device is lost, or laptop is stolen or tampered with, your data is still safe.  Also,  user’s data is backed up centrally and automatically so  you have better data integrity and reliability.</Typography>
              </Stack>
              <Typography>
                Ready to reduce your workstation overhead and increase productivity? <LinkCtr toUrl="/get-a-quote" withoutHoverColor='#000000' text='Contact us' /> and we can get your Virtual Workstations up and running!
              </Typography>
            </Stack>
          </TabPanel>
        </Box>
    </Box>
  )
}

export default WhatWeDoTabs