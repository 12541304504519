import React from 'react'
import { Box, Typography } from '@mui/material';
import Carousel from 'react-material-ui-carousel';
import clientSays from "../utilities/clientsSay.json";
import ClientSayItem from "./ClientSayItem";
const WhatOurClientsSay = () => {
  return (
    <Box 
       sx={{background:"url(/assets/images/other/concrete_1600x750.jpg)",
        backgroundRepeat:"no-repeat", backgroundSize:"cover", backgroundPosition:"center"}}
    >
        <Box sx={{padding:"3% 10%", color:"white", textAlign:"center"}}>
            <Typography variant='h5' sx={{letterSpacing:"0.03em", fontWeight:700, fontSize:"1.1rem", marginBottom:"20px"}}>
                WHAT OUR CLIENTS SAY
            </Typography>
            <Carousel autoPlay={false}
                indicatorContainerProps={{
                    style: {
                        marginTop: '30px', // 5
                        textAlign: 'center', // 4
                        
                    }
                }}
                indicatorIconButtonProps={{
                    style: {
                        marginRight:'5px',
                        border:'1px solid white'
                    }
                }}
                activeIndicatorIconButtonProps={{
                    style: {
                        backgroundColor: 'white', // 2
                        color:'white'
                    }
                }}
            >      
                {
                    clientSays.map(item => <ClientSayItem key={item.id} item={item}/>)
                }
            </Carousel>
        </Box>
    </Box>
  )
}

export default WhatOurClientsSay