import React from 'react'
import GetAQuote from '../components/GetAQuote'
import PageStructure from '../components/shared/PageStructure'

const GetAQuotePage = () => {
  return (
    <PageStructure text={'GET A QUOTE'} page={GetAQuote} />
  )
}

export default GetAQuotePage