import {Link} from '@mui/material';
import React, { useState } from 'react'

const LinkCtr = ({toUrl, text, hoverColor="#9c9c9c", withoutHoverColor="#03a9f4"}) => {
    const [isHover, setIsHover] = useState(false);
    const handleMouseEnter = () => {
        setIsHover(true);
     }; 
     const boxStyle = {
        color: isHover ? hoverColor : withoutHoverColor,
        textDecoration: isHover ? 'underline' :'none',
        cursor: 'pointer',
     };        
    const handleMouseLeave = () => {
        setIsHover(false);
    };
    return(
        <Link href={toUrl} style={boxStyle}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >{text}</Link> 
    )
}

export default LinkCtr