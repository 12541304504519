import { Box, Typography, useMediaQuery, useTheme } from '@mui/material'
import React from 'react'
import ContactUsRef from '../ContactUsRef';
import LinkCtr from '../shared/LinkCtr';

const WhatIsTheCloudCom = () => {
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.only("xs"));
  return (
    <React.Fragment>
        <Box sx={{width:'100%', display:'flex', flexDirection:'column', alignItems:'center'}} >
            <Box sx={{margin:'5% 10% 3% 10%', color:'#9c9c9c', fontSize:'15px !important', fontWeight:'400'}}>             
                <Typography component={'p'} sx={{textAlign:'center'}}>
                    <img decoding="async" style={{float:isMatch ? 'none' : 'right', margin:'9px 0 20px 28px', marginLeft:isMatch ? '0px': '28px'}} src="/assets/images/whatisthecloud/what-is-the-cloud-300x300.jpg" alt="" width="300" height="300" srcSet="/assets/images/whatisthecloud/what-is-the-cloud-300x300.jpg 300w, /assets/images/whatisthecloud/what-is-the-cloud-150x150.jpg 150w, /assets/images/whatisthecloud/what-is-the-cloud-768x768.jpg 768w, /assets/images/whatisthecloud/what-is-the-cloud-1024x1024.jpg 1024w, /assets/images/whatisthecloud/what-is-the-cloud-100x100.jpg 100w, /assets/images/whatisthecloud/what-is-the-cloud-1600x1600.jpg 1600w" sizes="(max-width: 300px) 100vw, 300px" />
                </Typography>
                <Typography sx={{marginTop:'16px'}} component={'p'}>Simply put, “the cloud” is just another name for the internet.</Typography>
                <Typography sx={{marginTop:'16px'}} component={'p'}>The term “cloud” causes a lot of confusion but it’s actually pretty simple.</Typography>
                <Typography sx={{marginTop:'16px'}} component={'p'}>The cloud (also called cloud computing) just means you are storing and using data and programs online instead of on your physical computer or network.</Typography>
                <Typography sx={{marginTop:'16px'}} component={'p'}>Whether you know it or not, you use the cloud everyday. Every time you send an email, Google something, login to Facebook or binge watch your favorite series on Netflix, you are using the cloud.</Typography>
                <Typography sx={{marginTop:'16px'}} component={'p'}>Analysts predict that over 50% of IT workloads will run in a cloud by 2020.</Typography>
                <Typography sx={{marginTop:'16px'}} component={'p'}>There are a number of good reasons <LinkCtr toUrl="/#whythecloud" text="(here are 4 of our favorites)" withoutHoverColor='#000000' /> but the takeaway is this: it gives you a level of flexibility and security that was just not possible in the past.</Typography>
                <Typography sx={{marginTop:'16px'}} component={'p'}>Plus, you no longer have to maintain server hardware, backups or plan for costly upgrades which leaves you free to focus on the things that matter, like growing your business.</Typography>
                <Typography sx={{marginTop:'16px'}} component={'p'}>At Cloud Service Geeks, we’ve chosen to partner exclusively with <a className='link' href="https://azure.microsoft.com/en-us/overview/what-is-cloud-computing/" target="_blank" rel="noopener">Microsoft Azure</a> because it has the most comprehensive security & compliance coverage of any cloud provider. 90% of Fortune 500 companies trust Microsoft, and we do too.</Typography>
                <Typography sx={{marginTop:'16px'}} component={'p'}>But wait, what about security? Learn more about security in our FAQ: <LinkCtr toUrl="/faq/is-the-cloud-secure" text= "Is the cloud secure?" withoutHoverColor='#000000'/></Typography>
            
            </Box>
        </Box>
        <ContactUsRef />
    </React.Fragment>
  )
}

export default WhatIsTheCloudCom