import React from 'react'
import { Box, Button, Stack, Typography, Link, useMediaQuery, useTheme } from '@mui/material'
// import { Link } from 'react-router-dom'
const ContactUsRef = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  return (
    <Box sx={{backgroundColor:"#f6f6f6 !important", width:"100%", marginTop:"30px"}}>
        <Box sx={{padding:"4% 10%"}}>
            <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} sx={{justifyContent:"center"}}>
                <Typography variant='h5'  className='sectionHeader' sx={{fontSize: isMobile ? '22px !important' : ''}}>
                Do you have questions? Contact us
                </Typography>
                <Box sx={{textAlign:'center'}}>
                  <Link href="/4">
                    <Button variant="contained" sx={{minWidth:"132px", maxWidth:"132px"}}>GET A TOUCH</Button>
                  </Link>
                </Box>
            </Stack>
        </Box>
    </Box>
  )
}

export default ContactUsRef