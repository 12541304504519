import React from 'react'
import { Box, Typography, Stack, TextField, Button } from '@mui/material'

const MoveMeToTheCloudPageCom = () => {
  return (
    <Box  sx={{width:'100%', marginTop:'5% 10%', textAlign:'center'}} >
        <Box className="container ">
            <iframe className="responsive-iframe" src="https://player.vimeo.com/video/236168605?autoplay=1"
            allowFullScreen="yes"></iframe>
        </Box>
        
    </Box>
  )
}

export default MoveMeToTheCloudPageCom