
import React from 'react'
import Carousel from 'react-material-ui-carousel';
import slider from "../utilities/slider.json";
import Item from "./Item";

const CarouselComp = () => {
  return (
    <Carousel
    indicatorContainerProps={{
      style: {
          marginTop: '-80px', // 5
          textAlign: 'center', // 4
          position: 'absolute',
          zIndex: '3',
      }

  }}
    >      
        {
            slider.map(item => <Item key={item.id} item={item}/>)
        }
    </Carousel>
  )
}

export default CarouselComp