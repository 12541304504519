import React, { useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom';
import CarouselComp from "./CarouselComp";
import ContactUs from "./ContactUs";
import FrequentyAskedQuestion from "./FrequentyAskedQuestion";
import LearnWhyTheCloud from "./LearnWhyTheCloud";
import MoveMeTheCloudComp from "./MoveMeTheCloudComp";
import WhatOurClientsSay from "./WhatOurClientsSay";
import WhatWeDo from "./WhatWeDo";
import WhyTheCloudComp from "./WhyTheCloudComp";

const MainPageComp = ({index}) => {
  const homeRef = useRef()
  const whyTheCloudCompRef = useRef();  
  const whatWeDoRef = useRef();
  const faqRef = useRef();
  const contactUsRef = useRef();
  const {id} = useParams();

  const scrollDown = (ref) => {
    window.scrollTo({
      top: ref.current.offsetTop - 64,
      behavior: 'smooth',
    });
  };
  const callScroll = (indx) =>{
    //('final: ', indx);
    if(indx == 0){
        scrollDown(homeRef);
    }else if(indx == 1){
        scrollDown(whyTheCloudCompRef);
    }else if(indx == 2){
        scrollDown(whatWeDoRef);
    }else if(indx == 3){
        scrollDown(faqRef);
    }else if(indx == 4){
        scrollDown(contactUsRef);
    }
  }
  useEffect(()=>{ 
    if(id == undefined ){
      callScroll(index);
    }
  }, [index])
  useEffect(() => {
    if(id !== undefined && id >= 0){
      callScroll(id);
    }

  }, [])
  return (
    <React.Fragment>
        <div ref={homeRef}>
            <CarouselComp />
        </div>
        <div id="whythecloud" ref={whyTheCloudCompRef} >
            <WhyTheCloudComp />
        </div>        
        <MoveMeTheCloudComp/>
        <div ref={whatWeDoRef} >
            <WhatWeDo/>
        </div>                
        <LearnWhyTheCloud />
        <div ref={faqRef}>
            <FrequentyAskedQuestion callScroll={callScroll}/>
        </div>        
        <WhatOurClientsSay/>
        <div ref={contactUsRef}>
            <ContactUs/>
        </div>        
    </React.Fragment>
  )
}

export default MainPageComp