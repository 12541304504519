import React from 'react'
import { Box, Card, CardContent, CardActions, CardMedia, Stack, Typography, useMediaQuery, useTheme, Link } from '@mui/material';
// import {Link} from 'react-router-dom';
import LinkCtr from './shared/LinkCtr';
const FrequentyAskedQuestion = ({callScroll}) => {
    const theme = useTheme();
    const isMatch = useMediaQuery(theme.breakpoints.down("md"));
    const learnMore = {
        fontSize: '13px',
        fontWeight: '600',
    }
  return (
    <Stack direction='column' sx={{padding:"3% 10%", marginTop:"30px", color:'#9c9c9c', fontFamily:"Open Sans,Arial,sans-serif;"}}>
        <Stack sx={{ width:"100%", textAlign:"center"}} spacing={2}>
            <Typography variant='h5' className='sectionHeader'>
                FREQUENTLY ASKED QUESTIONS
            </Typography>
            <Typography variant='body2' className='textColor'>
                Moving to the cloud is new so it makes sense that you will have questions. Below are a few of the most common ones.
            </Typography>
            <Typography variant='body2' className='textColor'>
                Have others? Your friendly Cloud Service Geeks team is happy to help.&nbsp;
                <Link className='link' onClick={() => callScroll(4)}>Contact us</Link>
                  &nbsp;and we’ll get you a speedy response.
            </Typography>
        </Stack>
        <Box sx={{width:'100%', textAlign:'center', display:'flex', justifyContent:'center', marginTop:"30px"}}>
            <Stack spacing={4} direction={{ xs: 'column', md: 'row' }}>
                <Card className='card' sx={{ maxWidth: 345, width: {isMatch} ? '100%': '20%', textAlign:"left" }}>
                    <Link href="/faq/what-is-the-cloud" >                    
                        <CardMedia
                            component="img"
                            alt="What is the cloud?"
                            image="/assets/images/thumbnail/what-is-the-cloud-thumbnail.jpg"
                            sx={{objectFit:"fill", height: isMatch ? "220px !important" : "152px !important"}}                    
                        />
                    </Link>
                    <CardContent>
                        <Typography gutterBottom variant="h5" className='cardHeader'>
                            What is the cloud?
                        </Typography>
                        <Typography variant="body2" className='textColor line2'>
                            Simply put, “the cloud” (also called cloud computing) is just another name for the internet. The term ‘cloud’ causes a lot of confusion but it’s actually pretty simple.
                        </Typography>
                    </CardContent>
                    <CardActions style={learnMore}>
                        <LinkCtr toUrl={"/faq/what-is-the-cloud"} text="READ MORE" />
                    </CardActions>
                </Card>
                <Card className='card' sx={{ maxWidth: 345, width: {isMatch} ? '100%': '20%', textAlign:"left" }}>
                    <Link href="/faq/is-the-cloud-secure">                    
                        <CardMedia
                            component="img"
                            alt="Is the cloud secure?"
                            image="/assets/images/thumbnail/is-the-cloud-secure-thumbnail.jpg"
                            sx={{objectFit:"fill", height: isMatch ? "220px !important" : "152px !important"}}                    
                        />
                    </Link>
                    <CardContent>
                    <Typography gutterBottom variant="h5" className='cardHeader' >
                        Is the cloud secure?
                    </Typography>
                    <Typography variant="body2" className='textColor line2'>
                        90% of Fortune 500s trust the Microsoft Cloud and so can you! Azure protects your assets through a rigorous methodology that focuses on security, privacy, and compliance.
                    </Typography>
                    </CardContent>
                    <CardActions style={learnMore}>
                        <LinkCtr toUrl={"/faq/is-the-cloud-secure"} text="READ MORE" />
                    </CardActions>
                </Card> 
                <Card className='card' sx={{ maxWidth: 345, width: {isMatch} ? '100%': '20%', textAlign:"left" }}>
                    <Link href="/faq/is-the-cloud-reliable">                    
                        <CardMedia
                            component="img"
                            alt="Is the cloud reliable?"
                            image="/assets/images/thumbnail/is-the-cloud-reliable-thumb.jpg" 
                            sx={{objectFit:"fill", height: isMatch ? "220px !important" : "152px !important"}}                  
                        />
                    </Link>
                    <CardContent>
                    <Typography gutterBottom variant="h5" className='cardHeader'>
                        Is the cloud reliable?
                    </Typography>
                    <Typography variant="body2" className='textColor line2'>
                        How about 99.99+% uptime. But we know reliability runs deeper for you than just uptime. Take a look at these other benefits of running your business in the cloud.
                    </Typography>
                    </CardContent>
                    <CardActions style={learnMore}>
                        <LinkCtr toUrl={"/faq/is-the-cloud-reliable"} text="READ MORE" />
                    </CardActions>
                </Card> 
                <Card className='card' sx={{ maxWidth: 345, width: {isMatch} ? '100%': '20%', textAlign:"left" }}>
                    <Link href="/faq/what-does-the-cloud-cost">                    
                        <CardMedia
                            component="img"
                            alt="green iguana"
                            image="/assets/images/thumbnail/what-does-the-cloud-cost-thumbnail.jpg"
                            sx={{objectFit:"fill", height: isMatch ? "220px !important" : "152px !important"}}                    
                        />
                    </Link>
                    <CardContent>
                    <Typography gutterBottom variant="h5" className='cardHeader'>
                        What does the cloud cost?
                    </Typography>
                    <Typography variant="body2" className='textColor line2'>
                        It’s one of the most asked questions: Is it less expensive to run your business in the cloud than in an on-prem IT environment? The answer is a definite maybe.
                    </Typography>
                    </CardContent>
                    <CardActions style={learnMore}>
                        <LinkCtr toUrl={"/faq/what-does-the-cloud-cost"} text="READ MORE" />
                    </CardActions>
                </Card>               
            </Stack>
        </Box>        
    </Stack>
  )
}

export default FrequentyAskedQuestion