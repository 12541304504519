import { Typography, Stack, Box, TextField, Button } from '@mui/material'
import React, { useState, useRef} from 'react';
import HCaptcha from "@hcaptcha/react-hcaptcha";
// import Axios from 'axios';
// import { baseApiUrl } from '../api/api-service';


const regEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
const source = "1";
const serverErrMsg = "Something went wrong.. please try again after some time!";
const validationErrMsg = "Please enter all the required fields.";

const ContactUs = () => {
  const [token, setToken] = useState(null);
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState(false);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errMsg, setErrorMsg] = useState(validationErrMsg);
  const [disableButton, setDisableButton] = useState(false);  

  const captchaRef = useRef(null);
  const handleSubmit = () => {
    if(validateInputs()){
        setDisableButton(true);
        document.getElementById("contactForm").submit();               
        // Axios.post(baseApiUrl + "contact", {
        //     token,
        //     name,
        //     email,
        //     subject,
        //     message,
        //     source
        // })
        // .then(response => {
        //     setSuccess(true);
        // })
        // .catch(({response}) => {
        //     setErrorMsg(serverErrMsg);
        //     setIsError(true);
        //     setDisableButton(false);
        // })
        // .finally(() => {
        //     captchaRef.current.resetCaptcha();
        //     setToken("");
        //     setName("");
        //     setEmail("");
        //     setSubject("");
        //     setMessage("");
        //     setDisableButton(false);
        // })
    }else{
        setErrorMsg(validationErrMsg);
        setIsError(true);
    }
  }
  const validateInputs = () => {
    setSuccess(false);
    setIsError(false);
    const boolEmail = email.trim() !== "" && email.trim().match(regEmail) ? true : false;
    const boolName = name.trim() !== "" ? true : false;
    const boolToken = (token !== null && token !== "")  ? true : false;
    setNameError(!boolName);
    setEmailError(!boolEmail);

    return boolEmail && boolName && boolToken;
  }
  return (
    <Stack direction='column' sx={{padding:"3% 10%", marginTop:"30px"}}>
        <Stack sx={{ width:"100%", textAlign:"center"}} spacing={2}>
            <Typography variant='h5' className='sectionHeader'>
                CONTACT US
            </Typography>
            <Typography variant='body2' className='textColor'>
                Call Cloud Service Geeks at (772) 888-3888 or complete the form below and we’ll connect with you.
            </Typography>
        </Stack>
        <Box sx={{width:'100%', textAlign:'center', display:'flex', justifyContent:'center', marginTop:"30px"}} className='textColor'>
        <form style={{width:'100%'}} name="contactForm" id="contactForm" action="contact.php" method="post">
            <Stack spacing={2} direction="column" sx={{width:'100%', alignItems:"left"}}>
            
                <TextField error={nameError} id="outlined-error" value={name} name="name"
                    label="Name" required onChange={(e) => setName(e.target.value)} />
                <TextField error={emailError} id="outlined-error" value={email} label="Email" name="email" required onChange={(e) => setEmail(e.target.value)} />
                <TextField label="Subject" name="subject" value={subject} onChange={(e) => setSubject(e.target.value)} />
                <TextField label="Message" name="message" value={message} multiline rows={10} onChange={(e) => setMessage(e.target.value)} />
                <Box textAlign={'left'}>
                    <HCaptcha
                        sitekey="26fad0e6-1068-4bee-9193-3e095e48dd5f"
                        onVerify={setToken}
                        ref={captchaRef}
                    />
                    <Typography sx={{marginTop:'15px', color:'green', display:success ? 'block' : 'none'}}>Thank you for your message. It has been sent.</Typography>
                    <Typography sx={{marginTop:'15px', color:'red', display:isError ? 'block' : 'none'}}>{errMsg}</Typography>
                </Box>
                
                <Button disabled={disableButton} variant="contained" sx={{maxWidth:"150px"}} onClick={handleSubmit}>Send Message</Button>
             
            </Stack>
            </form>
        </Box>
    </Stack>
  )
}

export default ContactUs